/* 
Style for cursorTrackingTooltip() 
---------------------------------
The JS function will updated the following css :root variables
    --curtooltip-position: absolute;
    --cursor-x: 0px;
    --cursor-y: 0px;
Defaults are set below in case these don't exist.
*/
*[data-curtooltip]:hover:after {
    content: attr(data-curtooltip);
    display: block;
    position: var(--curtooltip-position, absolute);
    top: var(--cursor-y, 0);
    left: var(--cursor-x, 0);
    isolation: isolate;
    z-index: 999999999;
    background: #fff;
    border: 1px solid #666;
    border-radius: 3px;
    padding: .4rem;
    font-size: 1rem;
}